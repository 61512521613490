import React, { Component } from 'react';
//import ReactGA from 'react-ga';

import './App.css';
import { Route, Routes } from "react-router-dom";
import MainPage from './Components/MainPage';
import HungryPuppyPrivacyPolicy from './Components/Subpages/HungryPuppyPrivacyPolicy';
import SantasTownPrivacyPolicy from './Components/Subpages/SantasTownPrivacyPolicy';
import NetAnalyzerPrivacyPolicy from './Components/Subpages/NetAnalyzerPrivacyPolicy';
import ChordDetectorPrivacyPolicy from './Components/Subpages/ChordDetectorPrivacyPolicy';

class App extends Component {

  constructor(props){
    super(props);

    //ReactGA.initialize('UA-110570651-1');
    //ReactGA.pageview(window.location.pathname);
  }

  render() {
    return (
      <Routes>
        <Route path="/" element={<MainPage />} />
        <Route path='hungry-puppy-privacy-policy' element={<HungryPuppyPrivacyPolicy />} />
        <Route path='santas-town-privacy-policy' element={<SantasTownPrivacyPolicy />} />
        <Route path='net-analyzer-privacy-policy' element={<NetAnalyzerPrivacyPolicy />} />
        <Route path='chord-detector-privacy-policy' element={<ChordDetectorPrivacyPolicy />} />

        <Route path='*' element={<MainPage />} />
      </Routes>
    );
  }
}

export default App;
